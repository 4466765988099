import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import * as Sentry from '@sentry/react'
import { Auth0Provider } from '@auth0/auth0-react'
import history from './utils/history'

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  )
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_URL,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/data\.pretto\.fr/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const providerConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENTID,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
}

const root = createRoot(document.getElementById('root'))
root.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>
)

import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'

import Loading from './components/Loading'
import NavBar from './components/NavBar'
import Dashboard from './views/Dashboard'
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'
import history from './utils/history'

import './App.css'

import initFontAwesome from './utils/initFontAwesome'
initFontAwesome()

const App = () => {
  const { isLoading, error } = useAuth0()

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5 overflow-hidden">
          <Switch>
            <Route path="/" exact component={Dashboard} />
          </Switch>
        </Container>
      </div>
    </Router>
  )
}

export default withAuthenticationRequired(App)

import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import './Dashboard.css'

const METABASE_ROOT_URL = process.env.REACT_APP_METABASE_ROOT_URL
const Dashboard = () => {
  const [dashboardUrl, setDashboardUrl] = useState()
  const { user, getIdTokenClaims } = useAuth0()

  const fetchAndSetDashboardUrl = async () => {
    const claims = await getIdTokenClaims()
    const token = claims[`${METABASE_ROOT_URL}/metabase_token`]

    setDashboardUrl(
      `${METABASE_ROOT_URL}/embed/dashboard/${token}#bordered=true&titled=true`
    )
  }

  useEffect(() => {
    if (user) {
      fetchAndSetDashboardUrl()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  if (!dashboardUrl) return null

  return (
    <>
      <iframe
        title="partner-dashboard"
        src={dashboardUrl}
        className="dashboard-iframe overflow-hidden"
      />
    </>
  )
}

export default Dashboard
